import * as React from 'react';
import { Route, useNavigate, Routes } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import { Col, Row, Progress, Collapse, Layout } from 'antd';
import { AppstoreAddOutlined, ThunderboltOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { PlatformCounts, defaultPlatformCounts } from '../ts-models';
import { MyResponsivePieCanvas, PieDataItem } from './charts/pie';
import { useData } from './glow/actions/use-data';

const { Panel } = Collapse;
const { Content } = Layout;

const introText = (
  <div>
    <p>
      If you are an application owner and want to request a software package for
      a classic Windows desktop application, or if you want to deploy an app
      for users on our Windows HC standard clients or mobile devices, you've
      come to the right place.{' '}
    </p>

    <p>
      Within this client application management framework, we offer different
      service workflows for application owners or country IT related to the
      Integration and Deployment of mobile apps (iOS/Android and Windows) and
      classical desktop applications for Windows. All Integration workflows
      include a security check by the Info Sec team.{' '}
    </p>

    <p>
      The service is designed for to the following client platforms: Windows,
      iOS, and Android operating systems.{' '}
    </p>

    <p>
      For more details about the different modules see the user guide in the
      Help section.{' '}
    </p><hr/>
    <p>
      In case of questions please do not hesitate to contact:
      <br />
      Team: GITC Standard Client & Mobility Team
      <br />
      E-mail:{' '}
      <a href="mailto:GITGOCDigitalWorkplace-StandardClient@heidelbergcement.com">
        GITGOCDigitalWorkplace-StandardClient@heidelbergcement.com{' '}
      </a>
      <br />
      Teams Channel:{' '}
      <a
        target="_blank"
        href="https://teams.microsoft.com/l/channel/19%3ae80890c92bd346ae804473e0f4db49a7%40thread.tacv2/Allgemein?groupId=2395550a-770b-4dd9-9747-b80d4f0133d7&tenantId=57952406-af28-43c8-b4de-a4e06f57476d"
        rel="noreferrer"
      >
        Silver monkey Teams Channel
      </a>
    </p>
  </div>
);

export function DashboardMasterView() {
  const navigate = useNavigate();

  const { data: appInProgressData } = useData<PlatformCounts>(
    `/api/statistics/apps-in-progress`,
    defaultPlatformCounts,
  );

  const { data: appInOperatingData } = useData<PlatformCounts>(
    `/api/statistics/apps-in-operating`,
    defaultPlatformCounts,
  );

  return (
    <Routes>
      <Route
        path={`/`}
        element={
          <Layout.Content style={{ flex: 1 }}>
            <Row
              gutter={24}
              style={{
                margin: 24,
              }}
            >
              <Col xl={16} lg={24} md={24} sm={24} xs={24}>
                <h1>Welcome to the Silver Monkey web portal </h1>
                <p>{introText}</p>
                <Row
                  gutter={0}
                  style={{
                    marginTop: 30,
                  }}
                >
                  <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                    <MyResponsivePieCanvas
                      title="Integration request in progress"
                      data={[
                        {
                          id: 'Win10Classic',
                          label: 'Classic',
                          value: appInProgressData.win10ClassicCount,
                          color: 'hsl(19, 70%, 50%)',
                        } as PieDataItem,
                        {
                          id: 'Win10App',
                          label: 'W10 App',
                          value: appInProgressData.win10AppCount,
                          color: 'hsl(213, 70%, 50%)',
                        } as PieDataItem,
                        {
                          id: 'Android',
                          label: 'Android',
                          value: appInProgressData.androidCount,
                          color: 'hsl(58, 70%, 50%)',
                        } as PieDataItem,
                        {
                          id: 'iOs',
                          label: 'iOs',
                          value: appInProgressData.iOsCount,
                          color: 'hsl(58, 70%, 50%)',
                        } as PieDataItem,
                        {
                          id: 'BrowserExt',
                          label: 'Browser Ext',
                          value: appInProgressData.browserExtensionCount,
                          color: '#26d95c',
                        } as PieDataItem,
                      ]}
                    />
                  </Col>

                  <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                    <MyResponsivePieCanvas
                      title="Applications in operating"
                      data={[
                        {
                          id: 'Windows Desktop Application',
                          label: 'Classic',
                          value: appInOperatingData.win10ClassicCount,
                          color: 'hsl(19, 70%, 50%)',
                        } as PieDataItem,
                        {
                          id: 'Win10App',
                          label: 'W10 App',
                          value: appInOperatingData.win10AppCount,
                          color: 'hsl(213, 70%, 50%)',
                        } as PieDataItem,
                        {
                          id: 'Android',
                          label: 'Android',
                          value: appInOperatingData.androidCount,
                          color: 'hsl(58, 70%, 50%)',
                        } as PieDataItem,
                        {
                          id: 'iOs',
                          label: 'iOs',
                          value: appInOperatingData.iOsCount,
                          color: 'hsl(58, 70%, 50%)',
                        } as PieDataItem,
                        {
                          id: 'BrowserExt',
                          label: 'Browser Ext',
                          value: appInOperatingData.browserExtensionCount,
                          color: '#26d95c',
                        } as PieDataItem,
                      ]}
                    />
                  </Col>
                  <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    We will share News regularly in our Silver monkey{' '}
                    <a
                      target="_blank"
                      href="https://teams.microsoft.com/l/channel/19%3ae80890c92bd346ae804473e0f4db49a7%40thread.tacv2/Allgemein?groupId=2395550a-770b-4dd9-9747-b80d4f0133d7&tenantId=57952406-af28-43c8-b4de-a4e06f57476d"
                      rel="noreferrer"
                    >
                      Teams Channel
                    </a>{' '}
                    with you and we would be happy if you would like to share
                    your ideas with us to improve the product. See you…{' '}
                  </Col>
                </Row>
              </Col>
              <Col xl={8} lg={24} md={24} sm={24} xs={24}>
                <h3>
                  <AppstoreAddOutlined /> Integration
                </h3>
                <p>
                  In this section you can request applications to be prepared
                  for a later deployment on our HeidelbergCement client devices.
                  This process includes workflow steps like request, security
                  check, and final approval by the application owner.{' '}
                </p>
                <Collapse defaultActiveKey={[]} ghost={true} accordion={true}>
                  <Panel
                    header="Windows Desktop Application Applications"
                    key="1"
                  >
                    <p>More information will be available soon.</p>
                  </Panel>
                  <Panel header="Win10, Android, iOS Apps" key="2">
                    <p>More information will be available soon.</p>
                  </Panel>
                </Collapse>
                <h3 style={{ marginTop: 20 }}>
                  <ThunderboltOutlined /> Deployment{' '}
                </h3>
                <p>
                  In this section you find the application deployment related
                  modules.{' '}
                </p>
                <Collapse defaultActiveKey={[]} ghost={true} accordion={true}>
                  <Panel header="Operations" key="1">
                    <p>More information will be available soon.</p>
                  </Panel>
                  <Panel header="Country Portfolio Management" key="2">
                    <p>More information will be available soon.</p>
                  </Panel>
                  <Panel header="Multi Target" key="3">
                    <p>More information will be available soon.</p>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </Layout.Content>
        }
      />
    </Routes>
  );
}

const Header = styled(PageHeader)``;

const MasterDetailContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10;
  flex: 1;
`;
